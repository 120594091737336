"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";


export class AgentRegisteredComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.role = null;
        this.roleCapablities = [];
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        this.innerHTML = this.render();
    }

    render() {
        return `
        <c-nav></c-nav>
            
            <div class="container page">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-xs-12">
                        <p>Welcome to the ADMIN HOME</p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-3 col-xs-12">
                        <div class="card" style="width: 18rem;">
                            <div class="card-body" >
                                <h5 class="card-title">Onboard Agent</h5>
                                <p class="card-text">Create a new agent and provide their contact info</p>
                                <a href="#/onboard-agent" class="btn btn-primary">Onboard agent</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <c-footer></c-footer>
        `;
    }

}
