"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";


export class HomeComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.role = null;
        this.roleCapablities = [];
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }

        RouterHandler.getInstance.router.navigate('#/');
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        this.innerHTML = this.render();
    }

    render() {
        return `
        <div>
            <c-banner></c-banner>
            <div class="container page">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-xs-12">
                        <p>Welcome to the P3 appointments scheduling app.</p>
                    </div>
                </div>
                ${this.auth ? `
                    <div class="row">
                        <div id="role-capabilities" class="col-md-6 offset-md-3 col-xs-12">Since you are an <b>${this.auth.userType}</b>, you have the following capabilities:
                            <ul class="capability-list">
                                ${this.roleCapablities.map(cap => {
                                    return `<li>${cap}</li>`;
                                }).join(' ')}
                            </ul>
                        </div>
                    </div>
                ` : ''}
            </div>
        </div>
        `;
    }

}
