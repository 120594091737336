import {Http} from "../http/http";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
"use strict";
export class COnboardAgentComponent extends HTMLElement {
    constructor() {
        super();
        this.handleRegistration = this.handleRegistration.bind(this);

        this.auth = null;
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {
    }

    disconnectedCallback() {
        this.$registerButton.removeEventListener('click', this.handleRegistration);
    }

    connectedCallback() {
        this.innerHTML = this.render();

        this.$username = this.querySelector('#username');
        this.$password = this.querySelector('#password');
        this.$email = this.querySelector('#email');
        this.$phone = this.querySelector('#phone');
        this.$firstName = this.querySelector('#firstname');
        this.$lastName = this.querySelector('#lastname');
        this.$conferencingUri = this.querySelector('#conferencing-uri');
        this.$dailyAppointmentQuota = this.querySelector('#daily-appointment-quota');
        this.$weeklyAppointmentQuota = this.querySelector('#weekly-appointment-quota');
        this.$blockBetweenAppointmentsMinutes = this.querySelector('#block-between-appointments-minutes');

        this.$form = this.querySelector('form');
        this.$messages = this.querySelector('.messages');

        this.$registerButton = this.querySelector('#register-button');
        this.$registerButton.addEventListener('click', this.handleRegistration);
    }

    handleRegistration(e) {
        if (this.$form.checkValidity()) {
            e.preventDefault();

            let data = {
                "agent": {
                    "email": this.$email.value,
                    "phone": this.$phone.value,
                    "firstName": this.$firstName.value,
                    "lastName": this.$lastName.value,
                    "conferencingUri": this.$conferencingUri.value,
                    "dailyAppointmentQuota": this.$dailyAppointmentQuota.value,
                    "weeklyAppointmentQuota": this.$weeklyAppointmentQuota.value,
                    "blockBetweenAppointmentsMinutes": this.$blockBetweenAppointmentsMinutes.value
                }
            };

            function validateEmail(email) {
              var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(email);
                }
            
            const agentEmail = this.$email.value;
            
            if (!validateEmail(agentEmail)){
              let createdMessage = document.createElement('div');
                  createdMessage.textContent = 'Please enter a valid email address';
                  while(this.$messages.firstChild) {
                    this.$messages.removeChild(this.$messages.firstChild);}
                  this.$messages.appendChild(createdMessage);
                  this.$messages.appendChild(document.createElement('p'));
              return;
            }

            Http.instance.doPost('/agents', JSON.stringify(data), true).then(r => {
                while(this.$messages.firstChild) {
                    this.$messages.removeChild(this.$messages.firstChild);
                }
                if (r.errors) {
                    for (var prop in r.errors) {
                        if(!r.errors.hasOwnProperty(prop)) continue;
                        r.errors[prop].forEach(m => {
                            let text = prop + " " + m;
                            let errorItem = document.createElement('li');
                            errorItem.textContent = text;
                            this.$messages.appendChild(errorItem);
                        });
                        this.$messages.appendChild(document.createElement('p'));
                    }
                } 
                else {
                  let createdMessage = document.createElement('div');
                  createdMessage.textContent = 'Agent onboarded';
                  this.$messages.appendChild(createdMessage);
                  this.$messages.appendChild(document.createElement('p'));
                }
            });
        }
    }

    

    render() {
        return `
        <c-nav></c-nav>

        <div class="onboard-agent-page">
          <div class="container page">
            <div class="row justify-content-center">
        
              <div class="col-md-8 col-xs-12">
                <h1 class="text-xs-center">Onboard Agent</h1>
                
				<div class="row justify-content-center">
                  <div class="col-md-5 col-xl-4">
                    <div class="messages"></div>
                  </div>
                </div> 

                <div class="form-container">
                <form>
                  <div class="row">
                    <div class="mb-3 col">
                      <label for="firstname" class="form-label">First name</label>
                      <input id="firstname" type="text" class="form-control">
                    </div>
                    <div class="mb-3 col">
                      <label for="lastname" class="form-label">Last name</label>
                      <input id="lastname" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email address</label>
                      <input id="email" type="email" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="phone" class="form-label">Contact phone</label>
                      <input id="phone" type="text" class="form-control" placeholder="555-555-5555">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="conferencing-uri" class="form-label">Conferencing link</label>
                      <input id="conferencing-uri" type="text" class="form-control" placeholder="https://zoom.us/j/223123123?pwd=12124234124">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="daily-appointment-quota" class="form-label">Daily appointment quota</label>
                      <input id="daily-appointment-quota" type="text" class="form-control" Value="0">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="weekly-appointment-quota" class="form-label">Weekly appointment quota</label>
                      <input id="weekly-appointment-quota" type="text" class="form-control" Value="0">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="block-between-appointments-minutes" class="form-label">Block between appointments minutes</label>
                      <input id="block-between-appointments-minutes" type="text" class="form-control" Value="0">
                    </div>
                  </div>
                  <button type="submit" id="register-button" class="btn btn-primary">Onboard agent</button>
                  
                </form>
                </div>
                
              </div>
        
            </div>
          </div>
        </div>

        <c-footer></c-footer>
`;
    }


}
