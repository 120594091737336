"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";


export class AdminHomeComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.role = null;
        this.roleCapablities = [];
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
       this.innerHTML = this.render();
    }

    render() {
        var blah = 'class="card h-100" style="width: 18rem;"'
        return `
        <c-nav></c-nav>
        <div class="agent-list-page">
            <div class="container page">
                <div class="row justify-content-center text-center">
                    <div class="col-sm-4">
                        <div class="card h-100">
                            <div class="card-body" >
                                <h5 class="card-title">Onboard Agent</h5>
                                <p class="card-text">
                                    Create a new agent<br/>
                                    Provide agent contact info<br/>
                                </p>
                                <a href="#/onboard-agent" class="btn btn-primary">Onboard agent</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card h-100">
                            <div class="card-body" >
                                <h5 class="card-title">Manage Agents</h5>
                                <p class="card-text">
                                    List/edit agents<br/>
                                    Get prospect scheduling links
                                </p>
                                <a href="#/manage-agents" class="btn btn-primary">Manage Agents</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card h-100">
                            <div class="card-body" >
                                <h5 class="card-title">List Meetings</h5>
                                <p class="card-text">
                                    List meetings<br/>
                                    Cancel/reschedule meetings</br>
                                </p>
                                <a href="#/list-meetings" class="btn btn-primary">List Meetings</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center text-center mt-4"> 
                    <div class="col-sm-4">
                        <div class="card h-100" >
                            <div class="card-body" >
                                <h5 class="card-title">Create Admin User</h5>
                                <p class="card-text">
                                    Create new admin user<br/>
                                </p>
                                <a href="#/create-admin-user" class="btn btn-primary">Create Admin User</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card h-100" >
                            <div class="card-body" >
                                <h5 class="card-title">Manage Admin Users</h5>
                                <p class="card-text">
                                    List/edit admin users<br/>
                                </p>
                                <a href="#/manage-admin-users" class="btn btn-primary">Manage Admin Users</a>
                            </div>
                        </div>
                    </div>
                </div> 
                        <!--
                        <div class="card" >
                            <div class="card-body" >
                                <h5 class="card-title">Onboard Agent</h5>
                                <p class="card-text">
                                    Create a new agent<br/>
                                    Provide agent contact info<br/>
                                    &nbsp;
                                </p>
                                <a href="#/onboard-agent" class="btn btn-primary">Onboard agent</a>
                            </div>
                        </div>
                        <div class="card" >
                            <div class="card-body" >
                                <h5 class="card-title">Manage Agents</h5>
                                <p class="card-text">
                                    List/edit agents<br/>
                                    Get prospect scheduling links
                                </p>
                                <a href="#/manage-agents" class="btn btn-primary">Manage Agents</a>
                            </div>
                        </div>
                        <div class="card" >
                            <div class="card-body" >
                                <h5 class="card-title">List Meetings</h5>
                                <p class="card-text">
                                    List meetings<br/>
                                    Cancel/reschedule meetings</br>
                                </p>
                                <a href="#/list-meetings" class="btn btn-primary">List Meetings</a>
                            </div>
                        </div>
                        <div class="card" >
                            <div class="card-body" >
                                <h5 class="card-title">Create Admin User</h5>
                                <p class="card-text">
                                    Create new admin user<br/>
                                </p>
                                <a href="#/create-admin-user" class="btn btn-primary">Create Admin User</a>
                            </div>
                        </div>
                        <div class="card" >
                            <div class="card-body" >
                                <h5 class="card-title">Manage Admin Users</h5>
                                <p class="card-text">
                                    List/edit admin users<br/>
                                </p>
                                <a href="#/manage-admin-users" class="btn btn-primary">Manage Admin Users</a>
                            </div>
                        </div>
                        -->

                </div>
            </div>
        </div>
    
        <c-footer></c-footer>
        `;
    }

}
