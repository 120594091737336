import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";

"use strict";

export class CLoginComponent extends HTMLElement {
    constructor() {
        super();
        this.$signinButton = null;
        this.$username = null;
        this.$password = null;
        this.$errorMessages = null;

        this.$form = null;
        this.doAuthentication = this.doAuthentication.bind(this);
        
        if (Authentication.instance.auth) {
          let user = Authentication.instance.auth;
          var rolePath = (user && user.userType) ? `#/${user.userType}` : '/';
          RouterHandler.getInstance.router.navigate(rolePath);
        }
        
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    disconnectedCallback() {
        this.$signinButton.removeEventListener('click', this.doAuthentication)
    }

    connectedCallback() {
        this.innerHTML = this.render();
        this.$signinButton = this.querySelector('#signin-button');
        this.$username = this.querySelector('#username');
        this.$password = this.querySelector('#password');
        this.$form = this.querySelector('form');
        this.$errorMessages = this.querySelector('.error-messages');
        this.$signinButton.addEventListener('click', this.doAuthentication)
    }

    doAuthentication(e) {
        if (this.$form.checkValidity()) {
            e.preventDefault();
            const username = this.$username.value;
            const password = this.$password.value;
            Authentication.instance.doAuthentication(username, password)
            .then(user => {
                var rolePath = (user && user.userType) ? `#/${user.userType}` : '/';
                RouterHandler.getInstance.router.navigate(rolePath);
            })
            .catch(errorMsg => {
                this.$errorMessages.innerHTML = `<p style="color:red">${errorMsg}</p>`;
            });
        }
    }

    render() {
        return `
        <c-nav></c-nav>

             <div class="auth-page">
              <div class="container page">
                <div class="row">
            
                  <div class="col-md-6 offset-md-3 col-xs-12">
                    <h1 class="text-xs-center">Sign in</h1>
                    
                    <div class="error-messages"></div>

                    <form>
                      <div class="mb-3">
                        <label for="username" class="form-label">Username</label>
                        <input id="username" type="text" class="form-control">
                      </div>
                      <div class="mb-3">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" class="form-control" id="password">
                      </div>
                      <button type="submit" id="signin-button" class="btn btn-primary">Sign in</button>
                    </form>
                  </div>
            
                </div>
              </div>
            </div>

            <c-footer></c-footer>
`;
    }


}
