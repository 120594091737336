"use strict";
export class CFooterComponent extends HTMLElement {
    constructor() {
        super();
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        var template = `
           
        <footer class="text-center text-lg-start text-muted footer">
            
            <section class="">
                <div class="container text-center">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xl-6 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        <img src="images/P3-Footer-Logo.svg" alt="P3-Footer-Logo" loading="lazy" style="max-width: 100%; height: auto;" >
                    </h6>
                    </div>
                </div>
                </div>
            </section>

            <div class="text-center p-4">
                © 2021 Copyright: P3 Appointments
            </div>
            </footer>
        `;
        this.innerHTML = template;
    }


}
