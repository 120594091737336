"use strict";
import {Http} from "../http/http";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Modal} from 'bootstrap';

export class ModifyAgentComponent extends HTMLElement {
    constructor() {
        super();
        this.handleUpdate = this.handleUpdate.bind(this);
        this.deleteAgent = this.deleteAgent.bind(this);

        this.auth = null;
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }

        const params = this.paramsFromLocationHash()
        this.agentId = params["id"];
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {
    }

    disconnectedCallback() {
        this.$updateAgentButton.removeEventListener('click', this.handleUpdate);
    }

    connectedCallback() {
        this.innerHTML = this.render();
        
        const agentInfoPath = `/agents/${this.agentId}`;
        
        this.$agentId = this.querySelector('#agentId');
        this.$firstName = this.querySelector('#firstname');
        this.$lastName = this.querySelector('#lastname');
        this.$email = this.querySelector('#email');
        this.$phone = this.querySelector('#phone');
        this.$conferencingUri = this.querySelector('#conferencing-uri');
        this.$dailyAppointmentQuota = this.querySelector('#daily-appointment-quota');
        this.$weeklyAppointmentQuota = this.querySelector('#weekly-appointment-quota');
        this.$blockBetweenAppointmentsMinutes = this.querySelector('#block-between-appointments-minutes');

        Http.instance.doGet(agentInfoPath, true).then(res => {
            return res.json();
        }).then(r => {
            this.$agentId.value = r.id;
            this.$firstName.value = r.firstName;
            this.$lastName.value = r.lastName;
            this.$email.value = r.email;
            this.$phone.value = r.phone;
            this.$conferencingUri.value = r.conferencingUri;
            this.$dailyAppointmentQuota.value = r.dailyAppointmentQuota;
            this.$weeklyAppointmentQuota.value = r.weeklyAppointmentQuota;
            this.$blockBetweenAppointmentsMinutes.value = r.blockBetweenAppointmentsMinutes;
        });

        this.$form = this.querySelector('form');
        this.$messages = this.querySelector('.messages');

        this.$updateAgentButton = this.querySelector('#update-agent-button');
        this.$updateAgentButton.addEventListener('click', this.handleUpdate);
        this.querySelector('#confirm-delete-agent-button').addEventListener('click', this.deleteAgent);
    }

    paramsFromLocationHash() {
        let search = location.hash;
        let hashes = search.slice(search.indexOf('?') + 1).split('&')
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    }

    handleUpdate(e) {
        if (this.$form.checkValidity()) {
            e.preventDefault();

            let data = {
                "agent": {
                    "id": this.$agentId.value,
                    "firstName": this.$firstName.value,
                    "lastName": this.$lastName.value,
                    "email": this.$email.value,
                    "phone": this.$phone.value,
                    "conferencingUri": this.$conferencingUri.value,
                    "dailyAppointmentQuota": this.$dailyAppointmentQuota.value,
                    "weeklyAppointmentQuota": this.$weeklyAppointmentQuota.value,
                    "blockBetweenAppointmentsMinutes": this.$blockBetweenAppointmentsMinutes.value
                }
            };
            
            Http.instance.doPut('/agents', JSON.stringify(data), true).then(r => {
                while(this.$messages.firstChild) {
                    this.$messages.removeChild(this.$messages.firstChild);
                }
                if (r.errors) {
                    r.errors.map((err) => {
                        let text = err["field"] + " " + err["defaultMessage"];
                        let errorItem = document.createElement('div');
                        errorItem.textContent = text;
                        this.$messages.appendChild(errorItem);
                        this.$messages.appendChild(document.createElement('p'));
                    });
                    this.$messages.appendChild(document.createElement('p'));
                }
                else {
                  let createdMessage = document.createElement('div');
                  createdMessage.textContent = 'Agent updated';
                  this.$messages.appendChild(createdMessage);
                  this.$messages.appendChild(document.createElement('p'));
                }
            });
        }
    }

    deleteAgent() {
      let deleteAgentModal = document.getElementById('confirm-modal');
      Modal.getInstance(deleteAgentModal).hide();
      let deleteMessagesElem = this.querySelector('.messages');
      Http.instance.doDelete(`/agents/${this.$agentId.value}`, true).then(r => {
          if (r && r.errors) {
              deleteMessagesElem.textContent = r.errors;
          } else {
              deleteMessagesElem.innerHTML = "The agent has been removed.</br></br>"
          }
      });
  }


    render() {
        return `
        <c-nav></c-nav>

        <div class="update-agent-page">
          <div class="container page">
            <div class="row justify-content-center">
              
              <div class="col-md-8 col-xs-12">
                <h1 class="text-xs-center">Modify Agent</h1>
                
                <div class="row justify-content-center">
                  <div class="col-md-5 col-xl-4">
                    <div class="messages"></div>
                  </div>
                </div> 

                <div class="form-container">
                <form>
                  <input id="agentId" type="hidden">
                  <div class="row">
                    <div class="mb-3 col">
                      <label for="firstname" class="form-label">First name</label>
                      <input id="firstname" type="text" class="form-control">
                    </div>
                    <div class="mb-3 col">
                      <label for="lastname" class="form-label">Last name</label>
                      <input id="lastname" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email address</label>
                      <input id="email" type="email" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="phone" class="form-label">Contact phone</label>
                      <input id="phone" type="text" class="form-control" placeholder="555-555-5555">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="conferencing-uri" class="form-label">Conferencing link</label>
                      <input id="conferencing-uri" type="text" class="form-control" placeholder="https://zoom.us/j/223123123?pwd=12124234124">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="daily-appointment-quota" class="form-label">Daily appointment quota</label>
                      <input id="daily-appointment-quota" type="text" class="form-control" placeholder="0">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="weekly-appointment-quota" class="form-label">Weekly appointment quota</label>
                      <input id="weekly-appointment-quota" type="text" class="form-control" placeholder="0">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="block-between-appointments-minutes" class="form-label">Block between appointments minutes</label>
                      <input id="block-between-appointments-minutes" type="text" class="form-control" placeholder="0">
                    </div>
                  </div>
                  
                  <button type="button" id="update-agent-button" class="btn btn-primary">Update agent</button>

                  <button type="button" id="delete-agent-button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirm-modal">Delete agent</button>
                  
                </form>
                </div>
                
              </div>
        
            </div>
          </div>
        </div>

        <c-footer></c-footer>


        <!-- Modal -->
        <div class="modal fade" id="confirm-modal" tabindex="-1" aria-labelledby="confirm-modal-label" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="confirm-modal-label">Confirm Agent Delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Delete this agent?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button id="confirm-delete-agent-button" type="button" class="btn btn-primary">Delete Agent</button>
              </div>
            </div>
          </div>
        </div>
`;
    }


}
