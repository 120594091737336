"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";
import {config} from "../config";


export class AgentHomeComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.role = null;
        this.roleCapablities = [];
        this.calData = null;
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
        
        const params = this.paramsFromLocationHash()
        this.agentRegistrationLinkId = params["id"];
        this.agentConnected = params["connected"] == "true" ? true : false;

        const removeDupeSlashesRegex = /([^:]\/)\/+/g;
        this.chronofyOauthUri = (config.backend_base_url + '/oauth2/authorization/chronify').replace(removeDupeSlashesRegex, "$1");
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        this.innerHTML = this.render();
        
        let homeNavLink = document.querySelector('#home-nav-link');
        if (homeNavLink) {
            homeNavLink.remove();
        }
        
        this.$agentActions = this.querySelector('#agent-actions');
        this.$errorMessages = this.querySelector('.error-messages');
        
        while(this.$errorMessages.firstChild) {
            this.$errorMessages.removeChild(this.$errorMessages.firstChild);
        }

        if (this.agentConnected) {
            let infoItem = document.createElement('div');
            infoItem.textContent = `Your scheduling account has been connected and prospects can now schedule meetings on your calendar.`;
            this.$errorMessages.appendChild(infoItem);
            return;
        }

        let invalidRegistrationLinkIdErr = `This page requires a valid invitation link 
            that was sent via email. Please review and click on the link provided in the email.`;
        if (!this.agentRegistrationLinkId) {
            let errorItem = document.createElement('div');
            errorItem.textContent = invalidRegistrationLinkIdErr;
            this.$errorMessages.appendChild(errorItem);
            return;
        }

        Authentication.instance.doAgentAuthentication(this.agentRegistrationLinkId)
            .then(user => {
                let connectCard = document.createElement('div');
                connectCard.style = "width: 18rem;";
                connectCard.className = "card";
                connectCard.innerHTML = `
                    <div class="card-body" >
                        <h5 class="card-title">Connect Calendars</h5>
                        <p class="card-text">Connect existing schedules/calendars</p>
                        <a href="${this.chronofyOauthUri}" class="btn btn-primary">Connect Calendars</a>
                    </div>
                `
                this.$agentActions.appendChild(connectCard);

                this.$homeNavLink = this.querySelector('.home-nav-link');
            })
            .catch(errors => {
                let errorItem = document.createElement('div');
                errorItem.textContent = invalidRegistrationLinkIdErr;
                this.$errorMessages.appendChild(errorItem);
            });
    }

    paramsFromLocationHash() {
        let search = location.hash;
        let hashes = search.slice(search.indexOf('?') + 1).split('&')
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    }

    render() {
        return `
        <c-nav></c-nav>

        <div class="agent-home-page">
            <div class="container page">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-xs-6">
                        <div class="error-messages"></div>
                        <div id="agent-actions"></div>
                    </div>
                </div>
            </div>
        </div>

        <c-footer></c-footer>
        `;
    }

}
