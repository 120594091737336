"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";

export class ScheduleSuccessComponent extends HTMLElement {

    constructor(){
        super();
        
    }

    connectedCallback() {
        this.innerHTML = this.render();

    }
    
    render() { 
        return `
        <div class="schedule-success-page">
            <div class="container page"></div>
                <h1 style=text-align:center;> Your meeting has been scheduled</h1>
                <h2 style=text-align:center;> You may close this window</h2>



        `

    }




}