"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";
import {Popover, Modal, Collapse} from 'bootstrap';
import {Datepicker} from 'vanillajs-datepicker';

export class ScheduleMeetingComponent extends HTMLElement {
    
    constructor() {
        super();
        this.selectedDate = new Date();
        this.availabilitySlots = {};
        this.handleDateChange = this.handleDateChange.bind(this);
        
        const params = this.paramsFromLocationHash()
        this.scheduleLinkId = params["id"];
        this.originalEventLinkId = params["orig-event"];

        this.scheduleMeeting = this.scheduleMeeting.bind(this);
        this.slotStartTime = new Date();
        this.slotEndTime = new Date();
        this.defaultViewDate = new Date()
        
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        this.innerHTML = this.render();
        
        const scheduleInfoPath = `/schedule-info?scheduleLinkId=${this.scheduleLinkId}`;
        
        Http.instance.doGet(scheduleInfoPath, false).then(res => {
            return res.json();
        }).then(r => {
            this.agentFirstName = r.firstName;
            this.agentLastName = r.lastName;
            var agentName = (r.firstName && r.lastName) ? `${r.firstName} ${r.lastName}` : "";
            var scheduleTitle = agentName ? `Schedule time with ${agentName}` : "";
            this.querySelector('#schedule-meeting-title').textContent = scheduleTitle;
        });

        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const availableSlotsPath = `/schedule-available-slots?scheduleLinkId=${this.scheduleLinkId}&timezone=${tz}`;
        Http.instance.doGet(availableSlotsPath, false).then(res => {
            return res.json();
        }).then(r => {
            this.availabilitySlots = r.availability;

            this.$timeSlots = this.querySelector('#time-slots');

            let availabilityDays = Object.keys(this.availabilitySlots)

            if (availabilityDays.length == 0) {
                this.querySelector('.schedule-messages').textContent = "There are no available dates to schedule an appointment.";
                this.querySelector('#finalize-meeting-button').remove();
                return;
            }

            this.defaultViewDate = this.isoDateToJSDate(availabilityDays[0]);
            let maxAvailableDate = new Date(availabilityDays[availabilityDays.length - 1]);

            let defaultMonth = this.defaultViewDate.toLocaleDateString('en-US', {month:'2-digit'});
            let defaultYear = this.defaultViewDate.toLocaleDateString('en-US', {year:'numeric'});
            let datesDisabled = [];

            let evalDate = new Date(defaultYear, defaultMonth-1, 1);
            while(evalDate <= maxAvailableDate) {
                let isoEvalDate = this.isoDateFromJSDate(evalDate);
                if (!(isoEvalDate in this.availabilitySlots)) {
                    datesDisabled.push(new Date(evalDate.getTime()));
                }  
                evalDate.setDate(evalDate.getDate() + 1);
            }

            const datePickerEl = document.getElementById('meeting-date');
            const datepicker = new Datepicker(datePickerEl, {
                buttonClass: 'btn',
                datesDisabled: datesDisabled,
                defaultViewDate: this.defaultViewDate,
                minDate: this.defaultViewDate,
                maxDate: maxAvailableDate
            });

            this.renderTimeSlots(datepicker, this.defaultViewDate);
            
            datePickerEl.addEventListener('changeDate', () => {
                this.handleDateChange(datepicker);
            });
            
            this.querySelector('#schedule-meeting-button').addEventListener('click', this.scheduleMeeting);
            this.$form = this.querySelector('form');

            let finalizeMeetingContainer = this.querySelector('#finalize-meeting-div');
            new Collapse(finalizeMeetingContainer).toggle();
        });

        this.$firstName = this.querySelector('#firstname');
        this.$lastName = this.querySelector('#lastname');
        this.$email = this.querySelector('#email');
        this.$phone = this.querySelector('#phone');
        this.$campus = this.querySelector('#campus');
        this.$district = this.querySelector('#district');
    }

    paramsFromLocationHash() {
        let search = location.hash;
        let hashes = search.slice(search.indexOf('?') + 1).split('&')
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    }

    isoDateFromJSDate(srcDate) {
        let yyyy = srcDate.getFullYear();
        let mm = srcDate.getMonth()+1;
        let dd = srcDate.getDate();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        return `${yyyy}-${mm}-${dd}`
    }

    isoDateToJSDate(srcISODate) {
        let dateParts = srcISODate.split('-');
        let yyyy = dateParts[0];
        let mm = dateParts[1];
        let dd = dateParts[2];

        return new Date(yyyy, (mm-1), dd);
    }

    handleDateChange(datepicker) {
        this.renderTimeSlots(datepicker, datepicker.getDate());
    }

    renderTimeSlots(datepicker, pickerDate) {
        while(this.$timeSlots.firstChild) {
            this.$timeSlots.removeChild(this.$timeSlots.firstChild);
        }

        let dayTimeSlots = [];
        dayTimeSlots = this.availabilitySlots[this.isoDateFromJSDate(pickerDate)];
        dayTimeSlots.map(timeSlot => {
            var startHour = new Date(timeSlot.slotStartTime).toLocaleTimeString([], {hour: 'numeric', minute: 'numeric'});
            var endHour = new Date(timeSlot.slotEndTime).toLocaleTimeString([], {hour: 'numeric', minute: 'numeric'});

            var timeSlotButton = document.createElement('button');
            timeSlotButton.classList.add("timeslot-btn");
            timeSlotButton.value = startHour;
            timeSlotButton.textContent = startHour + "-" + endHour;

            timeSlotButton.addEventListener('click', () => {
                this.slotStartTime = new Date(timeSlot.slotStartTime);
                this.slotEndTime = new Date(timeSlot.slotEndTime);
            });
            this.$timeSlots.appendChild(timeSlotButton);
        });
        this.selectedDate = new Date(this.pickerDateStr)
    }

    scheduleMeeting() {
        if (this.$form.checkValidity()){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var scheduleEvent = {
            "scheduleEvent": {
                "scheduleLinkId": this.scheduleLinkId,
                "summary": "Meet with an advisor",
                "startDate": this.slotStartTime.toISOString(),
                "endDate": this.slotEndTime.toISOString(),
                "timezone": tz,
                "description": "An initial meeting to talk with an advisor...",
                "firstName": this.$firstName.value,
                "lastName": this.$lastName.value,
                "email": this.$email.value,
                "phone": this.$phone.value,
                "campus": this.$campus.value,
                "district": this.$district.value,
                "originalEventLinkId": this.originalEventLinkId
            }
        };

        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
              }
             
        const prospectEmail = this.$email.value;
          
        if (!validateEmail(prospectEmail)){
            var invalidEmailMessage = this.querySelector('.schedule-messages');
            invalidEmailMessage.textContent = 'Please enter a valid email address';
            var reviewMeetingModal = document.getElementById('review-modal');
            Modal.getInstance(reviewMeetingModal).hide();
            return;
        }

        var reviewMeetingModal = document.getElementById('review-modal');
        Modal.getInstance(reviewMeetingModal).hide();
        var scheduleEventStr = JSON.stringify(scheduleEvent);
        var scheduleMessagesEl = this.querySelector('.schedule-messages');
        Http.instance.doPost('/schedule-events', scheduleEventStr).then(r => {
            if (r.errors) {
                scheduleMessagesEl.textContent = r.errors;
            } else {
                scheduleMessagesEl.textContent = "Your meeting has been scheduled";
            }
        })
        .then(window.location.replace ('#/schedule-success'));
        
    }}
           
    render() {
        return `
            <div class="schedule-meeting-page">
                <div class="container page">

                    <div class="row justify-content-center">
                        <div class="col-md-5 col-xl-4">
                            <div id="schedule-meeting-title"></div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-md-5 col-xl-4">
                            <div class="schedule-messages">
                            </div>
                        </div>
                    </div>
                    
                    <div class="row justify-content-center">
                            <div class="col-md-5 col-xl-3">
                                <div id="meeting-date"" data-date="${this.defaultViewDate.toLocaleDateString('en-us')}"></div>
                            </div>
                            <div class="col-md-4 col-xl-4"">
                                <div id="time-slots" class="btn-group-vertical timeslot-btn-group, timeslotBox" role="group">
                                </div>
                            </div>
                        </div>
 
                    <div class="row justify-content-center collapse" id="finalize-meeting-div">
                        <div class="col-md-4 col-xl-3">
                            <button type="button" id="finalize-meeting-button" class="btn" data-bs-toggle="modal"  data-bs-target="#review-modal">Finalize Meeting</button>
                        </div>
                    </div>


                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="review-modal" tabindex="-1" aria-labelledby="review-modal-label" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="review-modal-label">Finalize Meeting</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form>
                        <div class="row">
                            <div class="mb-3 col">
                            <label for="firstname" class="form-label">First name</label>
                            <input id="firstname" type="text" class="form-control" required>
                            </div>
                            <div class="mb-3 col">
                            <label for="lastname" class="form-label">Last name</label>
                            <input id="lastname" type="text" class="form-control" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="mb-3">
                            <label for="email" class="form-label">Email address</label>
                            <input id="email" type="email" class="form-control" required>
                            </div>
                            <div class="mb-3">
                            <label for="phone" class="form-label">Contact phone</label>
                            <input id="phone" type="text" class="form-control" placeholder="555-555-5555" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="mb-3 col">
                                <label for="district" class="form-label">District</label>
                                <input id="district" type="text" class="form-control" required>
                            </div>
                            <div class="mb-3 col">
                                <label for="campus" class="form-label">Campus</label>
                                <input id="campus" type="text" class="form-control" required>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button id="schedule-meeting-button" type="submit" class="btn btn-primary">Schedule Meeting</button>
                        </div>
                    </form>
                  </div>
                  
                </div>
              </div>
            </div>
            
        `;
    }


}
