"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";
import {Popover} from 'bootstrap';


export class ManageAgentsComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.agents = [];
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        Http.instance.doGet('/agents', true).then(res => {
            return res.json();
        }).then(r => {
            r.map(agent => { this.agents.push(agent) });

            var clipboard = new ClipboardJS('.btn');

            clipboard.on('success', function(e) {
                console.info('Action:', e.action);
                console.info('Text:', e.text);
                console.info('Trigger:', e.trigger);
                e.clearSelection();
            });

            this.innerHTML = this.render();

            var popoverTriggerList = [].slice.call(this.querySelectorAll('[data-bs-toggle="popover"]'))
            var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                var popover = new Popover(popoverTriggerEl);
                
                popoverTriggerEl.addEventListener('shown.bs.popover', () => {
                    setTimeout(() => {
                        popover.hide()
                    }, 400);
                });

                return popover
            });
        });
    }

    render() {
        return `
        <c-nav></c-nav>

        <div class="manage-agents-page">
            <div class="container page">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">Agent Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Schedule Link</th>
                        <th scope="col">Daily Quota</th>
                        <th scope="col">Daily Appointments</th>
                        <th scope="col">Weekly Quota</th>
                        <th scope="col">Weekly Appointments</th>
                    </tr>
                    </thead>
                    <tbody>

                    ${this.agents.map(agent => {
                        var loc = window.location;
                        var shareLink = loc.protocol + '//' + loc.hostname
                        shareLink += (loc.port ? ':' + loc.port : '');
                        shareLink += "/#/schedule-meeting?id=" + agent.scheduleLinkId;
                        
                        let scheduleLinkCol = `
                            ${shareLink}
                            <button id="copy-link-${agent.scheduleLinkId}" class="btn" data-schedule-share-link-id="${agent.scheduleLinkId}" data-bs-toggle="popover" data-bs-content="Copied" data-clipboard-text="${shareLink}">
                                Copy
                            </button>
                        `;
                        if (!agent.schedulingAccountConnected) {
                            scheduleLinkCol = `<div>Not connected</div>` 
                        }

                        return `
                            <tr>
                                <td><a href="/#/modify-agent?id=${agent.id}">${agent.firstName} ${agent.lastName}</a>  </td>
                                <td>${agent.email}</td>
                                <td>${agent.phone}</td>
                                <td class="schedule-link-col">${scheduleLinkCol}</td>
                                <td>${agent.dailyAppointmentQuota ? agent.dailyAppointmentQuota : 0}</th>
                                <td>${agent.currDayAppointmentCount ? agent.currDayAppointmentCount : 0}</th>
                                <td>${agent.weeklyAppointmentQuota ? agent.weeklyAppointmentQuota : 0}</th>
                                <td>${agent.currWeekAppointmentCount ? agent.currWeekAppointmentCount : 0}</th>
                            </tr>
                        `;
                    }).join(' ')}

                    </tbody>
                </table>
            </div>
        </div>

        <c-footer></c-footer>
        `;
    }

}
