import {Authentication} from "../auth/authentication";
import {config} from "../config";
import {RouterHandler} from "../router/router-handler";

const removeDupeSlashesRegex = /([^:]\/)\/+/g;

export class Http {
    constructor() {
        if (!Http.inst) {
            Http.inst = this;
        } else {
            throw new Error('use instance');
        }

        return Http.inst;
    }

    static get instance() {
        return Http.inst;
    }

    doGet(path, authentication) {
        let nullBody = null;
        return Http.instance.doHTTPCall('GET', path, nullBody, authentication);
    }

    doPost(path, body, authentication) {
        return Http.instance.doHTTPCall('POST', path, body, authentication);
    }

    doPut(path, body, authentication) {
        return Http.instance.doHTTPCall('PUT', path, body, authentication);
    }

    doDelete(path, authentication) {
        let nullBody = null;
        return Http.instance.doHTTPCall('DELETE', path, nullBody, authentication);
    }

    doHTTPCall(method, path, body, authentication) {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        };

        if (authentication === true) {
            const auth = Authentication.instance.auth;
            var token = null;
            if (auth) {
                token = auth.token;
            } else {
                Http.instance.logout();
            }
            headers['Authorization'] = 'Token ' + token;
        } 

        let uri = (config.backend_base_url + path).replace(removeDupeSlashesRegex, "$1");

        let requestParams = {
            headers: headers,
            method: method
        };
        if (body) {
            requestParams["body"] = body;
        }

        return fetch(uri, requestParams).then(function (response) {
            if (response.status === 401) {
                Http.instance.logout();
            } else {
                return new Promise((resolve, rej) => {
                    resolve(response);
                });
            }
        });
    }

    logout() {
        localStorage.removeItem('auth');
        RouterHandler.instance.router.navigate('#/login');
        return new Promise((resolve, rej) => {
            rej();
        });
    }
}
Http.inst = null;
