"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";
import {Popover} from 'bootstrap';


export class ManageAdminUsersComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.adminUsers = [];
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        if (this.auth) {
            Http.instance.doGet('/admin-users', true).then(res => {
                return res.json();
            }).then(r => {
                r.map(adminUser => { 
                    this.adminUsers.push(adminUser) 
                });

                this.innerHTML = this.render();
            });
        } 
    }

    render() {
        return `
        <c-nav></c-nav>

        <div class="manage-adminUsers-page">
            <div class="container page">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Username</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                    </tr>
                    </thead>
                    <tbody>

                    ${this.adminUsers.map(adminUser => {
                        return `
                            <tr>
                                <td><a href="/#/modify-admin-user?id=${adminUser.id}">${adminUser.firstName} ${adminUser.lastName}</a></td>
                                <td>${adminUser.username}</td>
                                <td>${adminUser.email}</td>
                                <td>${adminUser.phone}</td>
                            </tr>
                        `;
                    }).join(' ')}

                    </tbody>
                </table>
            </div>
        </div>

        <c-footer></c-footer>
        `;
    }

}
