"use strict";
import {Http} from "../http/http";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Modal} from 'bootstrap';

export class ModifyAdminUserComponent extends HTMLElement {
    constructor() {
        super();
        this.handleUpdate = this.handleUpdate.bind(this);
        this.deleteAdminUser = this.deleteAdminUser.bind(this);

        this.auth = null;
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }

        const params = this.paramsFromLocationHash()
        this.adminUserId = params["id"];
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {
    }

    disconnectedCallback() {
        this.$updateAdminUserButton.removeEventListener('click', this.handleUpdate);
        this.$deleteAdminUserButton.removeEventListener('click', this.deleteAdminUser);
    }

    connectedCallback() {
        this.innerHTML = this.render();
        
        const adminUserInfoPath = `/admin-users/${this.adminUserId}`;
        
        this.$adminUserId = this.querySelector('#adminUserId');
        this.$firstName = this.querySelector('#firstname');
        this.$lastName = this.querySelector('#lastname');
        this.$email = this.querySelector('#email');
        this.$phone = this.querySelector('#phone');

        Http.instance.doGet(adminUserInfoPath, true).then(res => {
            return res.json();
        }).then(r => {
            this.$adminUserId.value = r.id;
            this.$firstName.value = r.firstName;
            this.$lastName.value = r.lastName;
            this.$email.value = r.email;
            this.$phone.value = r.phone;
        });

        this.$form = this.querySelector('form');
        this.$messages = this.querySelector('.messages');

        this.$updateAdminUserButton = this.querySelector('#update-admin-user-button');
        this.$updateAdminUserButton.addEventListener('click', this.handleUpdate);

        this.$deleteAdminUserButton = this.querySelector('#confirm-delete-adminuser-button');
        this.$deleteAdminUserButton.addEventListener('click', this.deleteAdminUser);

        // this.querySelector('#confirm-delete-adminuser-button').addEventListener('click', this.deleteAdminUser);
    }

    paramsFromLocationHash() {
        let search = location.hash;
        let hashes = search.slice(search.indexOf('?') + 1).split('&')
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    }

    handleUpdate(e) {
        if (this.$form.checkValidity()) {
            e.preventDefault();

            let data = {
                "adminUser": {
                    "id": this.$adminUserId.value,
                    "firstName": this.$firstName.value,
                    "lastName": this.$lastName.value,
                    "email": this.$email.value,
                    "phone": this.$phone.value
                }
            };
            
            Http.instance.doPut('/admin-users', JSON.stringify(data), true).then(r => {
                while(this.$messages.firstChild) {
                    this.$messages.removeChild(this.$messages.firstChild);
                }
                if (r.errors) {
                    r.errors.map((err) => {
                        let text = err["field"] + " " + err["defaultMessage"];
                        let errorItem = document.createElement('div');
                        errorItem.textContent = text;
                        this.$messages.appendChild(errorItem);
                        this.$messages.appendChild(document.createElement('p'));
                    });
                    this.$messages.appendChild(document.createElement('p'));
                }
                else {
                  let createdMessage = document.createElement('div');
                  createdMessage.textContent = 'Admin user updated';
                  this.$messages.appendChild(createdMessage);
                  this.$messages.appendChild(document.createElement('p'));
                }
            });
        }
    }

    deleteAdminUser(e) {
      // e.preventDefault();
      let deleteAdminUserModal = document.getElementById('confirm-modal');
      Modal.getInstance(deleteAdminUserModal).hide();
      let deleteMessagesElem = this.querySelector('.messages');
      Http.instance.doDelete(`/admin-users/${this.$adminUserId.value}`, true).then(r => {
          if (r && r.errors) {
              deleteMessagesElem.textContent = r.errors;
          } else {
              deleteMessagesElem.innerHTML = "The admin user has been removed.</br></br>"
          }
      });
  }


    render() {
        let deleteButton = `<button type="button" id="delete-adminuser-button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirm-modal">Delete admin user</button>`
        let conditionalDeleteButton = (parseInt(this.adminUserId) !== this.auth.id) ? deleteButton : '';

        return `
        <c-nav></c-nav>

        <div class="update-admin-user-page">
          <div class="container page">
            <div class="row justify-content-center">
              
              <div class="col-md-8 col-xs-12">
                <h1 class="text-xs-center">Modify Admin User</h1>
                
                <div class="row justify-content-center">
                  <div class="col-md-5 col-xl-4">
                    <div class="messages"></div>
                  </div>
                </div> 

                <div class="form-container">
                <form>
                  <input id="adminUserId" type="hidden">
                  <div class="row">
                    <div class="mb-3 col">
                      <label for="firstname" class="form-label">First name</label>
                      <input id="firstname" type="text" class="form-control">
                    </div>
                    <div class="mb-3 col">
                      <label for="lastname" class="form-label">Last name</label>
                      <input id="lastname" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email address</label>
                      <input id="email" type="email" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="phone" class="form-label">Contact phone</label>
                      <input id="phone" type="text" class="form-control" placeholder="555-555-5555">
                    </div>
                  </div>
                  <button type="button" id="update-admin-user-button" class="btn btn-primary">Update admin user</button> 
                  ${conditionalDeleteButton}
                </form>
                </div>
                
              </div>
        
            </div>
          </div>
        </div>

        <c-footer></c-footer>

        <!-- Modal -->
        <div class="modal fade" id="confirm-modal" tabindex="-1" aria-labelledby="confirm-modal-label" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="confirm-modal-label">Confirm Admin User Delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Delete this admin user?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button id="confirm-delete-adminuser-button" type="button" class="btn btn-primary">Delete Admin User</button>
              </div>
            </div>
          </div>
        </div>
`;
    }


}
