"use strict";
import {Http} from "../http/http";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";

export class CreateAdminUserComponent extends HTMLElement {
    constructor() {
        super();
        this.handleRegistration = this.handleRegistration.bind(this);

        this.auth = null;
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {
    }

    disconnectedCallback() {
        this.$registerButton.removeEventListener('click', this.handleRegistration);
    }

    connectedCallback() {
        this.innerHTML = this.render();
        
        this.$username = this.querySelector('#username');
        this.$password = this.querySelector('#password');
        this.$firstName = this.querySelector('#firstname');
        this.$lastName = this.querySelector('#lastname');
        this.$email = this.querySelector('#email');
        this.$phone = this.querySelector('#phone');
        
        this.$form = this.querySelector('form');
        this.$messages = this.querySelector('.messages');

        this.$registerButton = this.querySelector('#register-button');
        this.$registerButton.addEventListener('click', this.handleRegistration);
    }

    handleRegistration(e) {
        if (this.$form.checkValidity()) {
            e.preventDefault();

            let data = {
                "adminUser": {
                    "username": this.$username.value,
                    "password": this.$password.value,
                    "firstName": this.$firstName.value,
                    "lastName": this.$lastName.value,
                    "email": this.$email.value,
                    "phone": this.$phone.value
                }
            };
            Http.instance.doPost('/admin-users', JSON.stringify(data), true).then(r => {
                while(this.$messages.firstChild) {
                    this.$messages.removeChild(this.$messages.firstChild);
                }
                if (r.errors) {
                    for (var prop in r.errors) {
                        if(!r.errors.hasOwnProperty(prop)) continue;
                        r.errors[prop].forEach(m => {
                            let text = prop + " " + m;
                            let errorItem = document.createElement('div');
                            errorItem.textContent = text;
                            this.$messages.appendChild(errorItem);
                            this.$messages.appendChild('&nbsp;');
                        });
                    }
                    this.$messages.appendChild(document.createElement('p'));
                }
                else {
                  let createdMessage = document.createElement('div');
                  createdMessage.textContent = 'Admin user created';
                  this.$messages.appendChild(createdMessage);
                  this.$messages.appendChild(document.createElement('p'));
                }
            });
        }
    }


    render() {
        return `
        <c-nav></c-nav>

        <div class="onboard-agent-page">
          <div class="container page">
            <div class="row justify-content-center">
              
              <div class="col-md-8 col-xs-12">
                <h1 class="text-xs-center">Create Admin User</h1>
                
                <div class="row justify-content-center">
                  <div class="col-md-5 col-xl-4">
                    <div class="messages"></div>
                  </div>
                </div> 

                <div class="form-container">
                <form>
                  <div class="row">
                    <div class="mb-3 col">
                      <label for="username" class="form-label">Username</label>
                      <input id="username" type="text" class="form-control">
                    </div>
                    <div class="mb-3 col">
                      <label for="password" class="form-label">Password</label>
                      <input id="password" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3 col">
                      <label for="firstname" class="form-label">First name</label>
                      <input id="firstname" type="text" class="form-control">
                    </div>
                    <div class="mb-3 col">
                      <label for="lastname" class="form-label">Last name</label>
                      <input id="lastname" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email address</label>
                      <input id="email" type="email" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="phone" class="form-label">Contact phone</label>
                      <input id="phone" type="text" class="form-control" placeholder="555-555-5555">
                    </div>
                  </div>
                  <button type="submit" id="register-button" class="btn btn-primary">Create admin user</button>
                  
                </form>
                </div>
                
              </div>
        
            </div>
          </div>
        </div>

        <c-footer></c-footer>
`;
    }


}
