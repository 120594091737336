import {Authentication} from "./authentication";

const roleAdmin = "admin";
const roleAgent = "agent";

const protectedPaths = {
    "/admin": {requiredRole: roleAdmin},
    "/agent": {requiredRole: roleAgent},
    "/onboard-agent": {requiredRole: roleAdmin},
    "/manage-agents": {requiredRole: roleAdmin},
    "/list-meetings": {requiredRole: roleAdmin},
    "/create-admin-user": {requiredRole: roleAdmin},
    "/manage-admin-users": {requiredRole: roleAdmin},
    "/modify-admin-user": {requiredRole: roleAdmin},
    "/modify-agent": {requiredRole: roleAdmin}
};

export class AuthDefender {
    static canActivate(path) {
        let authUser = Authentication.instance.auth;
        let componentRole = (path && protectedPaths[path]) ? protectedPaths[path].requiredRole : null;
        let roleSatisfied = (authUser && componentRole) ? Authentication.userHasRole(authUser, componentRole) : true;
        return (authUser) ? roleSatisfied : false;
    }
}
