import {config} from "../config";

const removeDupeSlashesRegex = /([^:]\/)\/+/g;

export class Authentication {
    constructor() {
        if (!Authentication.inst) {
            Authentication.inst = this;
        } else {
            throw new Error('use instance');
        }

        this._callbacks = [];
        return Authentication.inst;
    }

    get auth() {
        return JSON.parse(localStorage.getItem('auth'));
    }

    set auth(value) {
        localStorage.setItem('auth', JSON.stringify(value));
    }

    onAuthenticate(callback) {
        this._callbacks.push(callback);
    }


    doAuthentication(username, password) {
        let user = {
            'user': {
                'username': username, 'password': password
            }
        };
        return new Promise((resolve, reject) => {
            let uri = (config.backend_base_url + '/users/login').replace(removeDupeSlashesRegex, "$1");
            fetch(uri, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user),
                credentials: "include"
            })
            .then(resp => {
                if (resp.status === 401) {
                    reject("Invalid Username or Password")
                } else {
                    resp.json().then(res => {
                        if (res.user) {
                            this.auth = res.user;
                            resolve(res.user);
                            setTimeout(() => {
                                this._callbacks.forEach(callback => callback(res.user));
                            });
                        } else {
                            reject(res.message)
                        }
                    });
                };
            });
        });

    }

    doAgentAuthentication(registrationLinkId) {
        let authReq = {
            'registrationLinkId': registrationLinkId
        };
        return new Promise((resolve, reject) => {
            let uri = (config.backend_base_url + '/agents/registration-login').replace(removeDupeSlashesRegex, "$1");
            fetch(uri, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(authReq),
                credentials: "include"
            }).then(res=>res.json()).then(res => {
                if (res.id) {
                    let agent = {
                        "id": res.id,
                        "firstName": res.firstName,
                        "lastName": res.lastName,
                        "email": res.email,
                        "phone": res.phone,
                        "conferencingUri": res.conferencingUri,
                        "registrationLinkId": res.registrationLinkId,
                        "registrationEmailSent": res.registrationEmailSent,
                        "connectReminderEmailSent": res.connectReminderEmailSent,
                        "scheduleLinkId": res.scheduleLinkId,
                        "schedulingAccountConnected": res.schedulingAccountConnected
                    }
                    this.auth = agent;
                    resolve(agent);
                    setTimeout(() => {
                        this._callbacks.forEach(callback => callback(agent));
                    });
                } else {
                    reject(res.message)
                }
            });
        });

    }

    static get instance() {
        return Authentication.inst;
    }

    static userHasRole(user, role) {
        // var hasRole = false;
        // if (user && user.roles) {
        //     hasRole = user.roles.some(r => {
        //         var extractedRole = r.name ? r.name.split("_")[1].toLowerCase() : null;
        //         return extractedRole === role;
        //     });
        // }
        // return hasRole;
        return user.userType === role;
    }
}
Authentication.inst = null;
