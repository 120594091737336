"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";
import {config} from "../config";


export class ConnectCalendarComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.role = null;
        this.roleCapablities = [];
        this.calData = null;
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        if (this.auth) {
            Http.instance.doGet('/oauth2/authorization/chronify', true).then(res => {
                RouterHandler.instance.router.navigate(res.url);
                return new Promise((resolve, rej) => {
                    rej();
                });
            });
        } 
    }

    render() {
        return `
        <div>
           Trying stuff...
        </div>
        `;
    }

}
