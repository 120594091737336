"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";
import {Popover} from 'bootstrap';
import {Datepicker} from 'vanillajs-datepicker';

export class ListEventsComponent extends HTMLElement {
    constructor() {
        super();
        this.auth = null;
        this.events = [];
        if (Authentication.instance.auth) {
            this.auth = Authentication.instance.auth;
        }
        this.handleDateChange = this.handleDateChange.bind(this);
        this.listEvents = this.listEvents.bind(this);
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    toLocalIsoString(date) {
        var pad = (n) => { return n < 10 ? '0' + n : n };        
        return date.getFullYear() + '-'
            + pad(date.getMonth() + 1) + '-'
            + pad(date.getDate())
    }

    connectedCallback() {
        this.innerHTML = this.render();

        const datePickerEl = document.querySelector('input[id="list-meetings-for-date"]');
        const datepicker = new Datepicker(datePickerEl, {
            autohide: true,
            buttonClass: 'btn',
        });
        datePickerEl.addEventListener('changeDate', () => {
            this.handleDateChange(datepicker);
        });

        if (this.auth) {
            this.listEvents(new Date());
        }
    }

    listEvents(date) {
        this.$meetingRows = document.querySelectorAll('tr[class="meeting-row"],tr[class="status-meeting-row"]');
        if (this.$meetingRows) {
            this.$meetingRows.forEach(element => {
                element.remove();
            });
        }
        
        const targetDate = this.toLocalIsoString(date);
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const listEventsPath = `/schedule-events?date=${targetDate}&timezone=${tz}`;
        Http.instance.doGet(listEventsPath, true).then(res => {
            return res.json();
        }).then(r => {
            this.events = [];
            r.map(event => {
                let agent = event.agent;
                let prospect = event.prospect;
                
                let meetingListRow = document.createElement('tr');
                meetingListRow.classList.add("meeting-row");
                
                let cancelled = (event.cancelDate ? true : false);
                let rescheduled = (event.originalAppointment ? true : false);
                
                meetingListRow.appendChild(this.createEventTD(`${agent.firstName} ${agent.lastName}`, cancelled, rescheduled));
                meetingListRow.appendChild(this.createEventTD(`${agent.email}`, cancelled, rescheduled));
                meetingListRow.appendChild(this.createEventTD(`${prospect.firstName} ${prospect.lastName}`, cancelled, rescheduled));
                meetingListRow.appendChild(this.createEventTD(`${prospect.email}`, cancelled, rescheduled));
                meetingListRow.appendChild(this.createEventTD(`${prospect.district}`, cancelled, rescheduled));
                meetingListRow.appendChild(this.createEventTD(`${prospect.campus}`, cancelled, rescheduled));

                const startHour = new Date(event.startDate).toLocaleTimeString('en-US', { hour: 'numeric'})
                const endHour = new Date(event.endDate).toLocaleTimeString('en-US', { hour: 'numeric'});
                meetingListRow.appendChild(this.createEventTD(`${startHour} - ${endHour}`, cancelled, rescheduled));

                let meetingListBody = document.querySelector('#meeting-list').getElementsByTagName('tbody')[0];
                
                meetingListBody.appendChild(meetingListRow);

                if (!cancelled && !rescheduled) {
                    let appointmentLinkId = event.appointmentLinkId;
                    meetingListRow.appendChild(this.createEventTD(`<a class="btn" href="#/cancel-meeting?id=${appointmentLinkId}" role="button">Cancel</a>`,
                        cancelled, rescheduled));
                    meetingListRow.appendChild(this.createEventTD(`<a class="btn" href="#/cancel-meeting?id=${appointmentLinkId}&reschedule-meeting=true" role="button">Reschedule</a>`,
                        cancelled, rescheduled));
                }

                if (cancelled){
                    let cancelInfoRow = document.createElement('tr');
                    cancelInfoRow.classList.add("status-meeting-row");
                    let tdElem = document.createElement('td');
                    tdElem.colSpan = 7;
                    let cancelDate = new Date(event.cancelDate);
                    let cancelMsg =  `<em>Cancelled on ${cancelDate.toLocaleDateString()} ${cancelDate.toLocaleTimeString()}</em>`;
                    if (event.cancelAdminUserFirstName && event.cancelAdminUserLastName) {
                        cancelMsg += ` by admin user ${event.cancelAdminUserFirstName} ${event.cancelAdminUserLastName}`
                    } else {
                        cancelMsg += ` by prospect`
                    }
                    tdElem.innerHTML = cancelMsg;
                    cancelInfoRow.appendChild(tdElem);
                    meetingListBody.appendChild(cancelInfoRow);
                }
                if (rescheduled){
                    let cancelInfoRow = document.createElement('tr');
                    cancelInfoRow.classList.add("status-meeting-row");
                    let tdElem = document.createElement('td');
                    tdElem.colSpan = 7;
                    let originalEvent = event.originalEvent;
                    let origDate = new Date(originalEvent.startDate).toLocaleDateString();
                    let origStartHour = new Date(originalEvent.startDate).toLocaleTimeString('en-US', { hour: 'numeric'})
                    let origEndHour = new Date(originalEvent.endDate).toLocaleTimeString('en-US', { hour: 'numeric'})
                    tdElem.innerHTML = `<em>Rescheduled from meeting originally scheduled on ${origDate} ${origStartHour} - ${origEndHour}</em>`;
                    cancelInfoRow.appendChild(tdElem);
                    meetingListBody.appendChild(cancelInfoRow);
                }
            });
        });
    }

    createEventTD(value, cancelled, rescheduled) {
        let tdElem = document.createElement('td');
        let innerHTML = value;
        if (cancelled) {
            innerHTML = `<del>${value}</del>`;
            tdElem.classList.add("cancelled-meeting-row-cell");
        }
        if (rescheduled) {
            tdElem.classList.add("rescheduled-meeting-row-cell");
        }
        tdElem.innerHTML = innerHTML;
        return tdElem;
    }

    handleDateChange(datepicker) {
        this.listEvents(new Date(datepicker.getDate()));
    }

    render() {
        return `
        <c-nav></c-nav>

        <div class="list-events-page">
            <div class="container page">

                <div class="col-md-4 col-xs-6">
                    <div class="form-container">
                        <form>
                        <div class="row">
                            <div class="mb-1 col">
                                <label for="list-meetings-for-date" class="form-label">Report date: </label>
                                <input id="list-meetings-for-date" type="text" class="form-control" value="${new Date().toLocaleDateString('en-us')}">
                            </div>
                        </div>
                        </form>
                    </div>
                </div>

                <table class="table" id="meeting-list">
                    <thead>
                    <tr>
                        <th scope="col">Agent Name</th>
                        <th scope="col">Agent Email</th>
                        <th scope="col">Prospect Name</th>
                        <th scope="col">Prospect Email</th>
                        <th scope="col">Prospect District</th>
                        <th scope="col">Prospect Campus</th>
                        <th scope="col">Event Time</th>
                        <th scope="col">Cancel</th>
                        <th scope="col">Reschedule</th>
                    </tr>
                    </thead>
                    <tbody></tbody>
                </table>
            </div>
        </div>
        
        <c-footer></c-footer>
        `;
    }

}
