import {layoutComponents} from "../components/layout/index";
import {HomeComponent} from "../pages/home.comp";
import {AdminHomeComponent} from "../pages/admin-home.comp";
import {AgentHomeComponent} from "../pages/agent-home.comp";
import {CLoginComponent} from "../pages/login.comp";
import {CRegisterComponent} from "../pages/register.comp";
import {RouterOutlet} from "../router/router-outlet";
import {ComponentRegistry} from "./component-registry";
import {ProfileComponent} from "../pages/profile.comp";
import {UserInfoComponent} from "../components/user-info.comp";
import {ConnectCalendarComponent} from "../pages/connect-calendar.comp";
import {COnboardAgentComponent} from "../pages/onboard-agent.comp";
import {AgentRegisteredComponent} from "../pages/agent-registered.comp";
import {ManageAgentsComponent} from "../pages/manage-agents.comp";
import {ScheduleMeetingComponent} from "../pages/schedule-meeting.comp";
import {CancelMeetingComponent} from "../pages/cancel-meeting.comp";
import {ListEventsComponent} from "../pages/list-events.comp";
import {CreateAdminUserComponent} from "../pages/create-admin-user.comp";
import {ManageAdminUsersComponent} from "../pages/manage-admin-users.comp";
import {ModifyAdminUserComponent} from "../pages/modify-admin-user.comp";
import {ModifyAgentComponent} from "../pages/modify-agent.comp";
import {ScheduleSuccessComponent} from "../pages/schedule-success.comp";


export class Core {
    constructor() {
        if (!Core.inst) {
            Core.inst = this;
        } else {
            throw new Error('use instance');
        }

        ComponentRegistry.register(components);

        return Core.inst;
    }

    static get instance() {
        return Core.inst;
    }
}
Core.inst = null;

const components = [
    ...layoutComponents,
    {
        tagName: 'router-outlet',
        component: RouterOutlet
    },
    {
        tagName: 'c-home',
        component: HomeComponent
    },
    {
        tagName: 'c-admin-home',
        component: AdminHomeComponent
    },
    {
        tagName: 'c-agent-home',
        component: AgentHomeComponent
    },
    {
        tagName: 'c-connect-calendar',
        component: ConnectCalendarComponent
    },
    {
        tagName: 'c-login',
        component: CLoginComponent
    },
    {
        tagName: 'c-register',
        component: CRegisterComponent
    },
    {
        tagName: 'c-profile',
        component: ProfileComponent
    },
    {
        tagName: 'user-info',
        component: UserInfoComponent
    },
    {
        tagName: 'c-onboard-agent',
        component: COnboardAgentComponent
    },
    {
        tagName: 'c-agent-registered',
        component: AgentRegisteredComponent
    },
    {
        tagName: 'c-manage-agents',
        component: ManageAgentsComponent
    },
    {
        tagName: 'c-schedule-meeting',
        component: ScheduleMeetingComponent
    },
    {
        tagName: 'c-cancel-meeting',
        component: CancelMeetingComponent
    },
    {
        tagName: 'c-list-events',
        component: ListEventsComponent
    },
    {
        tagName: 'c-create-admin-user',
        component: CreateAdminUserComponent
    },
    {
        tagName: 'c-manage-admin-users',
        component: ManageAdminUsersComponent
    },
    {
        tagName: 'c-modify-admin-user',
        component: ModifyAdminUserComponent
    },
    {
        tagName: 'c-modify-agent',
        component: ModifyAgentComponent
    },
    {
        tagName: 'c-schedule-success',
        component: ScheduleSuccessComponent
  
    },
];
