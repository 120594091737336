import {Http} from "../http/http";
import {RouterHandler} from "../router/router-handler";
"use strict";

export class ProfileComponent extends HTMLElement {
    constructor(params) {
        super();
        this.$logoutButton = null;
        this.logoutHandler = this.logoutHandler.bind(this);
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    disconnectedCallback() {
        this.$logoutButton.removeEventListener('click', this.logoutHandler);
    }

    connectedCallback() {
        this.innerHTML = this.render();
        this.$logoutButton = this.querySelector('#logoutButton');
        this.$logoutButton.addEventListener('click', this.logoutHandler);
        this.$errorMessages = this.querySelector('.error-messages');
    }

    logoutHandler(e) {
        e.preventDefault();
        localStorage.removeItem('auth');
        location.href = '/';
    }

    render() {
        return `
        <c-nav></c-nav>

<div class="profile-page">
  <div class="container page">
    <div class="row align-items-center">
        <div class="col-md-6 offset-md-3 col-xs-12">
            <div class="card" style="width: 18rem;">
                <div class="card-body" >
                    <h5 class="card-title">Logout</h5>
                    <p class="card-text">Log out of the site</p>
                    <button id="logoutButton" class="btn btn-outline-danger">Logout</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>

<c-footer></c-footer>
        `;
    }


}
