"use strict";
import {Authentication} from "../auth/authentication";
import {RouterHandler} from "../router/router-handler";
import {Http} from "../http/http";
import {Modal} from 'bootstrap';

export class CancelMeetingComponent extends HTMLElement {
    constructor() {
        super();
        const params = this.paramsFromLocationHash()
        this.appointmentLinkId = params["id"];
        this.rescheduleMeeting = params["reschedule-meeting"];
        this.event = null;
        this.cancelMeeting = this.cancelMeeting.bind(this);
        this.agentScheduleLinkId = null;

        this.adminUser = null;
        if (Authentication.instance.auth) {
            this.adminUser = Authentication.instance.auth;
        }
    }

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    connectedCallback() {
        this.innerHTML = this.render();
       
        const listEventsPath = `/cancelable-event?appointmentLinkId=${this.appointmentLinkId}`;
        Http.instance.doGet(listEventsPath, false).then(res => {
            return res.json();
        }).then(event => {
            let agent = event.agent;
            let prospect = event.prospect;

            this.agentScheduleLinkId = agent.scheduleLinkId;
            
            const meetDate = new Date(event.startDate).toLocaleDateString('en-US')
            const startHour = new Date(event.startDate).toLocaleTimeString('en-US', { hour: 'numeric'})
            const endHour = new Date(event.endDate).toLocaleTimeString('en-US', { hour: 'numeric'});

            let meetingDetailElem = document.querySelector('#event-detail');
            meetingDetailElem.innerHTML = `
                Your scheduled meeting:<br/>
                ${meetDate} ${startHour} - ${endHour}<br/>
                with ${agent.firstName} ${agent.lastName}<br/>
            `;

            let confirmButtonContainer = this.querySelector('#confirm-meeting-button-container');
            let btnElem = document.createElement('button');
            btnElem.id = "confirm-meeting-button";
            btnElem.classList.add("btn");
            btnElem.setAttribute("data-bs-toggle", "modal");
            btnElem.setAttribute("data-bs-target", "#confirm-modal");
            
            let confirmButtonLabel = this.rescheduleMeeting ? "Reschedule Meeting" : "Cancel Meeting";
            btnElem.innerHTML=confirmButtonLabel;
            
            confirmButtonContainer.appendChild(btnElem);
            
            this.querySelector('#cancel-meeting-button').addEventListener('click', this.cancelMeeting);
        });
    }

    paramsFromLocationHash() {
        let search = location.hash;
        let hashes = search.slice(search.indexOf('?') + 1).split('&')
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    }

    cancelMeeting() {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let cancelEvent = {
            "appointmentLinkId": this.appointmentLinkId,
        };
        if (this.adminUser) {
            cancelEvent["cancelAdminUserId"] = this.adminUser.id;
        }
        let cancelMeetingModal = document.getElementById('confirm-modal');
        Modal.getInstance(cancelMeetingModal).hide();
        let cancelEventStr = JSON.stringify(cancelEvent);
        let cancelMessagesElem = this.querySelector('.cancel-messages');
        Http.instance.doPost('/cancel-event', cancelEventStr).then(r => {
            if (r.errors) {
                cancelMessagesElem.textContent = r.errors;
            } else {
                cancelMessagesElem.innerHTML = "Your meeting has been cancelled.</br></br>"
            }
            if (this.rescheduleMeeting) {
                let scheduleMeetingPath = `/schedule-meeting?id=${this.agentScheduleLinkId}&orig-event=${this.appointmentLinkId}`;
                RouterHandler.getInstance.router.navigate(scheduleMeetingPath);
            }
        });
    }

    render() {
        return `
            <div class="cancel-meeting-page">
                <div class="container page">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-xl-4">
                            <div class="cancel-messages">
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-xl-3">
                            <div id="event-detail"></div><br/>
                            <div id="confirm-meeting-button-container"></div><br/>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="confirm-modal" tabindex="-1" aria-labelledby="confirm-modal-label" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="confirm-modal-label">Confirm Meeting ${this.rescheduleMeeting ? `Reschedule` : `Cancel`}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    ${this.rescheduleMeeting ? `Reschedule` : `Cancel`} this meeting?
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button id="cancel-meeting-button" type="button" class="btn btn-primary">${this.rescheduleMeeting ? `Reschedule` : `Cancel`} Meeting</button>
                  </div>
                </div>
              </div>
            </div>
            
        `;
    }

}
