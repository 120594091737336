var Navigo = require('navigo');
import {CLoginComponent} from "../pages/login.comp";
import {AuthDefender} from "../auth/auth-defender";
import {ProfileComponent} from "../pages/profile.comp";
import {HomeComponent} from "../pages/home.comp";
import {AdminHomeComponent} from "../pages/admin-home.comp";
import {AgentHomeComponent} from "../pages/agent-home.comp";
import {COnboardAgentComponent} from "../pages/onboard-agent.comp";
import {AgentRegisteredComponent} from "../pages/agent-registered.comp";
import {ManageAgentsComponent} from "../pages/manage-agents.comp";
import {ScheduleMeetingComponent} from "../pages/schedule-meeting.comp";
import {CancelMeetingComponent} from "../pages/cancel-meeting.comp";
import {ListEventsComponent} from "../pages/list-events.comp";
import {CreateAdminUserComponent} from "../pages/create-admin-user.comp";
import {ManageAdminUsersComponent} from "../pages/manage-admin-users.comp";
import {ModifyAdminUserComponent} from "../pages/modify-admin-user.comp";
import {ModifyAgentComponent} from "../pages/modify-agent.comp";
import {ScheduleSuccessComponent} from "../pages/schedule-success.comp";

export class RouterHandler {
    constructor() {
        if (!RouterHandler.instance) {
            RouterHandler.instance = this;
        } else {
            throw new Error('use getInstance');
        }

        var root = null;
        var useHash = true;
        var hash = '#';
        this.router = new Navigo(root, useHash, hash);
        return RouterHandler.instance;
    }

    static get getInstance() {
        return RouterHandler.instance;
    }

    static inject(component) {
        const outlet = document.querySelector('router-outlet');
        while (outlet.firstChild) {
            outlet.removeChild(outlet.firstChild);
        }
        outlet.appendChild(component);
    }

    init() {
        const routes = [
            {path: '/login', resolve: CLoginComponent},
            {path: '/profile/:username', resolve: ProfileComponent, canActivate: AuthDefender.canActivate},
            {path: '/admin', resolve: AdminHomeComponent, canActivate: AuthDefender.canActivate},
            {path: '/onboard-agent', resolve: COnboardAgentComponent},
            {path: '/agent', resolve: AgentHomeComponent},
            {path: '/agent-registered', resolve: AgentRegisteredComponent},
            {path: '/manage-agents', resolve: ManageAgentsComponent, canActivate: AuthDefender.canActivate},
            {path: '/schedule-meeting', resolve: ScheduleMeetingComponent},
            {path: '/cancel-meeting', resolve: CancelMeetingComponent},
            {path: '/list-meetings', resolve: ListEventsComponent, canActivate: AuthDefender.canActivate},
            {path: '/create-admin-user', resolve: CreateAdminUserComponent, canActivate: AuthDefender.canActivate},
            {path: '/manage-admin-users', resolve: ManageAdminUsersComponent, canActivate: AuthDefender.canActivate},
            {path: '/modify-admin-user', resolve: ModifyAdminUserComponent, canActivate: AuthDefender.canActivate},
            {path: '/modify-agent', resolve: ModifyAgentComponent, canActivate: AuthDefender.canActivate},
            {path: '/schedule-success', resolve: ScheduleSuccessComponent},
        ];

        this.router.on(() => {
            RouterHandler.inject(new CLoginComponent())
        }).resolve();

       routes.forEach(route => {
            this.router.on(
                route.path,
                (params) => {
                    RouterHandler.inject(new route.resolve(params))
                },
                {
                    before: (done, params) => {
                        if (!route.canActivate || route.canActivate(route.path)) {
                            done();
                        } else {
                            this.router.navigate('/');
                            done(false);
                        }
                    }
                }
            ).resolve();
        });

    }
}
RouterHandler.instance = null;

